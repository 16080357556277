import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { IconContext } from 'react-icons';
import { FaShoppingCart, FaPhoneAlt, FaRegClock } from "react-icons/fa";

import Dropdown from "./Dropdown";

import Logo from "./Logo";

// import LogoImg from "../../assets/rigdom.webp";
import LogoImg from "../assets/rigdom-logo-transparente.png";

import EventEmitter from '../utils/events';
import SocialMedia from "./SocialMedia";

const Cart = styled.div`
  width: fit-content;
  cursor: pointer;
`;

const Mobile = styled.div`
    display: block;
    height: 110px;
    @media(min-width: 1024px) {
       display: none;
    }
`;

const CartOn = styled.div`
    display: flex;
    // flex-direction: column;
    align-items: center;
`;

const CartQuantity = styled.p`
    width: fit-content;
    margin: 0 5px;
    font-size: 20px;
    text-align: center;
`;

const Desktop = styled.div`
    display: block;

    @media(max-width: 1024px) {
        display: none;
    }
`;

// TOPBAR
const TopBar = styled.div`
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #aecf40;
    color: #FFFFFF;
    font-size: 22px;
`;

const TopBarRightSide = styled.div`
    display: flex;
    align-items: center;
    padding: 0px;
    margin: 0px;
`;

const TopBarFont = styled.p`
    color: #FFFFFF;
    font-size: 18px;
`;

// HEADER
const Header = styled.div`
    background-color: #5241d3;
    font-size: 14px;
    color: #FFFFFF;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media(max-width: 1024px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
    }
`;

const Contato = styled.div`
    width: 280px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const WorkingTime = styled.div`
    width: 280px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const Heading = (props) => {
    const sessionCart = sessionStorage?.getItem("cart")
    const [cart, setCart] = useState(sessionCart);
    const [cartQuantity, setCartQuantity] = useState(sessionStorage?.getItem("cart") ? (JSON.parse(sessionStorage.getItem("cart")).length) : null);

    const addCart = (event) => {
        setCart(sessionStorage?.getItem("cart"));
        const itens = sessionStorage?.getItem("cart") ?? '[]';
        setCartQuantity(JSON.parse(itens).length ?? 0);
    }

    useEffect(() => {
        setCart(sessionCart)
    }, [sessionCart])

    EventEmitter.subscribe('addCart', (event) => addCart(event));

    return (
        <>
            <Desktop>
                <TopBar>
                    <SocialMedia />
                    <TopBarRightSide>
                        <Cart>
                            <Link to="/cart">
                                {cart ?
                                    cartQuantity > 0 ?
                                        <CartOn>
                                            <IconContext.Provider value={{ size: '16px' }} >
                                                <FaShoppingCart />
                                            </IconContext.Provider>
                                            <CartQuantity>
                                                {cartQuantity}
                                            </CartQuantity>
                                            <TopBarFont>{cartQuantity > 1 ? "itens" : "item"}</TopBarFont>
                                        </CartOn>
                                        :
                                        <IconContext.Provider value={{ size: '16px' }} >
                                            <FaShoppingCart />
                                        </IconContext.Provider>
                                    :
                                    <IconContext.Provider value={{ size: '16px' }} >
                                        <FaShoppingCart />
                                    </IconContext.Provider>
                                }
                            </Link>
                        </Cart>
                    </TopBarRightSide>
                </TopBar>

                <Header>
                    <Contato>
                        <IconContext.Provider value={{ size: '25px', color: "#fbc900" }} >
                            <FaPhoneAlt />
                        </IconContext.Provider>
                        <div>
                            <p>(14) 9 9835-4217</p>
                            {props.info.phone_number_1 && <p>{props.info.phone_number_1}</p>}
                            {/* {props.info.phone_number_2 && <p>{props.info.phone_number_2}</p>} */}
                            {props.info.phone_number_3 && <p>{props.info.phone_number_3}</p>}
                            {props.info.email_1 && <p>{props.info.email_1}</p>}
                            {/* { props.info.email_2 && <p>{ props.info.email_2 }</p> } */}
                        </div>
                    </Contato>
                    <Logo url={LogoImg} alt="Rigdom" />
                    <WorkingTime>
                        <IconContext.Provider value={{ size: '45px', color: "#fbc900" }} >
                            <FaRegClock />
                        </IconContext.Provider>
                        <p>
                            {props.info.office_hour}
                        </p>
                    </WorkingTime>
                </Header>
            </Desktop>

            <Mobile>
                <Header>
                    <Dropdown info={props.info} />
                    <Logo url={LogoImg} alt="Rigdom" />
                    <Cart>
                        <Link to="/cart">
                            {cart ?
                                cartQuantity > 0 ?
                                    <CartOn>
                                        <IconContext.Provider value={{ size: '35px' }} >
                                            <FaShoppingCart />
                                        </IconContext.Provider>
                                        <CartQuantity>
                                            {cartQuantity}
                                        </CartQuantity>
                                    </CartOn>
                                    :
                                    <IconContext.Provider value={{ size: '35px' }} >
                                        <FaShoppingCart />
                                    </IconContext.Provider>
                                :
                                <IconContext.Provider value={{ size: '35px' }} >
                                    <FaShoppingCart />
                                </IconContext.Provider>
                            }
                        </Link>
                    </Cart>
                </Header>
            </Mobile>
        </>
    )
}

export default Heading;
import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); /* Cor branca com 70% de opacidade */
  z-index: 1; /* Certifica-se de que o overlay esteja acima dos elementos subjacentes */
  pointer-events: auto; /* Bloqueia eventos de clique nos elementos subjacentes */
`;

const DisabledSection = styled.div`
  position: relative; /* Necessário para posicionar o overlay corretamente */
  /* Estilos da seção desativada aqui */
`;

const FormWithDisabledSection = ({ children, isEnabled }) => {
    return (
        <>
            {isEnabled ?
                    children
                :
                <DisabledSection>
                    {children}
                    <Overlay />
                </DisabledSection>
            }
        </>
    );
};

export default FormWithDisabledSection;

import { useState } from 'react';
import ChakraFlex from './ChakraFlex';
import { GreenCheck, Input, InputTitle, InputWrapper, NumberBadge, SaveButton, Title } from './FormComponents';

const PersonalInfoForm = (props) => {
    // States
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isNomeValid, setIsNomeValid] = useState(false);
    const [nome, setNome] = useState('')
    const [isCpfValid, setIsCpfValid] = useState(false);
    const [isDataNascimentoValid, setIsDataNascimentoValid] = useState(false);
    const [isCelularValid, setIsCelularValid] = useState(false);
    const [person, setPerson] = useState({
        email: "",
        name: "",
        cpf: "",
        cellphone: "",
        birth_date: ""
    });

    // Funções handleChange para cada campo
    const handleChangeEmail = (e) => {
        const value = e.target.value;

        setPerson({ ...person, email: value })
        updateUserInfo('person', { ...person, email: value })
        if (RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,8}$/).test(value)) {
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false)
        }
    }

    const handleChangeNome = (e) => {
        const value = e.target.value;
        setPerson({ ...person, name: value })
        updateUserInfo('person', { ...person, name: value })
        if (RegExp(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u).test(value)) {
            setNome(value)
            setIsNomeValid(true);
        } else {
            setNome(value)
            setIsNomeValid(false)
        }
    };

    const handleChangeCpf = (e) => {
        const value = e.target.value;
        setPerson({ ...person, cpf: value.replace(/[^0-9]/g, '') })
        updateUserInfo('person', { ...person, cpf: value.replace(/[^0-9]/, '') })

        if (isValidCPF(value)) {
            setIsCpfValid(true); // CPF é válido
        } else {
            setIsCpfValid(false); // CPF é inválido
        }
    };

    const isValidCPF = (cpf) => {
        cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

        if (cpf.length !== 11) return false;

        // Verifica se todos os dígitos são iguais, o que tornaria o CPF inválido
        if (/^(\d)\1+$/.test(cpf)) return false;

        // Calcula o primeiro dígito verificador
        let sum = 0;
        for (let i = 0; i < 9; i++) {
            sum += parseInt(cpf.charAt(i)) * (10 - i);
        }
        let remainder = 11 - (sum % 11);
        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(cpf.charAt(9))) return false;

        // Calcula o segundo dígito verificador
        sum = 0;
        for (let i = 0; i < 10; i++) {
            sum += parseInt(cpf.charAt(i)) * (11 - i);
        }
        remainder = 11 - (sum % 11);
        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(cpf.charAt(10))) return false;

        return true; // CPF é válido
    }

    const handleChangeDataNascimento = (e) => {
        const value = e.target.value;
        setPerson({ ...person, birth_date: value })
        updateUserInfo('person', { ...person, birth_date: value })

        // Converte a data de nascimento em um objeto Date
        const birthDate = new Date(value);
        // Obtém a data atual
        const currentDate = new Date();

        // Calcula a idade subtraindo o ano atual pelo ano de nascimento
        const age = currentDate.getFullYear() - birthDate.getFullYear();

        // Verifica se a pessoa tem pelo menos 18 anos
        if (age >= 18) {
            setIsDataNascimentoValid(true); // Data de nascimento válida e maior de 18 anos
        } else {
            setIsDataNascimentoValid(false); // Data de nascimento válida, mas menor de 18 anos
        }

    };

    const handleChangeCelular = (e) => {
        const value = e.target.value;
        setPerson({ ...person, cellphone: value })
        updateUserInfo('person', { ...person, cellphone: value })

        // Expressão regular para validar o número de celular (pelo menos 10 dígitos)
        const celularRegex = /^[1-9][0-9]{10}$/;

        if (celularRegex.test(value.replace(/[^0-9]/g, ''))) {
            setIsCelularValid(true); // Número de celular válido
        } else {
            setIsCelularValid(false); // Número de celular inválido
        }
    };

    const handleValidate = () => {
        if (isEmailValid && isNomeValid && isCpfValid && isDataNascimentoValid && isCelularValid) {
            updateUserInfo('card', { name: nome })
            setActiveStep(2)
        }
    }


    // Estado PAI
    const { userInfo, updateUserInfo, setActiveStep } = props;

    return (
        <ChakraFlex direction="column"
            style={{
                backgroundColor: '#f2f2f2',  // Fundo levemente cinza claro
                border: '1px solid #ccc',   // Contorno de 1 pixel de cor cinza claro
                padding: '15px',            // Padding de 10 pixels em todos os lados
                borderRadius: '8px',        // Borda mais arredondada
            }}
        >
            <ChakraFlex>
                <NumberBadge>1</NumberBadge>
                <Title>Informações Pessoais</Title>
            </ChakraFlex>

            <InputWrapper>
                <InputTitle>E-mail (seu@e-mail.com.br)</InputTitle>
                <ChakraFlex>
                    <Input
                        width="89%"
                        type="email"
                        placeholder="Seu e-mail"
                        value={userInfo.person.email ?? ''}
                        onChange={handleChangeEmail}
                    />
                    {isEmailValid && <GreenCheck show={isEmailValid}>&#10003;</GreenCheck>}
                </ChakraFlex>
            </InputWrapper>

            <InputWrapper>
                <InputTitle>Nome completo (João da Silva)</InputTitle>
                <ChakraFlex>
                    <Input
                        width="89%"
                        type="text"
                        placeholder="Seu nome completo"
                        value={userInfo.person.name ?? ''}
                        onChange={handleChangeNome}
                    />
                    {isNomeValid && <GreenCheck>&#10003;</GreenCheck>}
                </ChakraFlex>
            </InputWrapper>

            <InputWrapper>
                <InputTitle>CPF (123.456.789-10)</InputTitle>
                <ChakraFlex>
                    <Input
                        width="89%"
                        type="text"
                        placeholder="Seu CPF"
                        value={userInfo.person.cpf ?? ''}
                        onChange={handleChangeCpf}
                        maxLength={14}
                    />
                    {isCpfValid && <GreenCheck>&#10003;</GreenCheck>}
                </ChakraFlex>
            </InputWrapper>

            <InputWrapper>
                <InputTitle>Data de Nascimento (12/04/1972)</InputTitle>
                <ChakraFlex>
                    <Input
                        width="89%"
                        type="date"
                        placeholder="Sua data de nascimento"
                        // value={userInfo.person.birth_date}
                        onChange={handleChangeDataNascimento}
                    />
                    {isDataNascimentoValid && <GreenCheck>&#10003;</GreenCheck>}
                </ChakraFlex>
            </InputWrapper>

            <InputWrapper>
                <InputTitle>Telefone celular (11 99111-1111)</InputTitle>
                <ChakraFlex>
                    <Input
                        width="89%"
                        type="text"
                        placeholder="Seu celular"
                        value={userInfo.person.cellphone ?? ''}
                        onChange={handleChangeCelular}
                    />
                    {isCelularValid && <GreenCheck>&#10003;</GreenCheck>}
                </ChakraFlex>
            </InputWrapper>

            <SaveButton onClick={handleValidate}>Salvar Dados</SaveButton>
        </ChakraFlex>
    )
}


export default PersonalInfoForm;
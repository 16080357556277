import styled from "styled-components";
import { Link } from "react-router-dom";

const Root = styled.img`
  cursor: pointer;
  height: auto;
  
  @media(max-width: 800px) {
    width: 120px;
    height: auto;
  }
`;

const Logo = (src, alt) => {
  return (
    <Link to="/">
      <Root src={src.url} alt={alt} />
    </Link>
  )
}

export default Logo;
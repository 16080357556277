import styled, { css } from 'styled-components'
const Title = styled.div`
  font-size: 24px;
  font-weight:600;
`;

const InputTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const InputWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : '100%')};
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Input = styled.input`
  width: ${(props) => (props.width ? props.width : '100%')};
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Para que a largura inclua o padding */
`;

const SaveButton = styled.button`
  padding: 10px 20px;
  background-color: #00a65a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #008c52; /* Cor mais escura ao passar o mouse */
  }

  /* Estilo quando isLoading é true */
  ${(props) =>
    props.isLoading &&
    css`
      background-color: #ccc; /* Cor de fundo enquanto está carregando */
      cursor: not-allowed; /* Cursor indisponível enquanto está carregando */

      &:hover {
        background-color: #ccc; /* Cor mais escura ao passar o mouse */
      }
    `}
`;

const GreenCheck = styled.span`
  width: 20px; /* Largura fixa para o GreenCheck */
  color: green;
  font-size: 18px;
  margin-left: 5px;
`;

const NumberBadge = styled.div`
  width: 30px;
  height: 30px;
  background-color: rgb(82, 65, 211);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  margin-right: 5px;
`;
export { GreenCheck, Title, InputTitle, InputContainer, InputWrapper, Input, SaveButton, NumberBadge };
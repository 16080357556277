import React, { Suspense, lazy, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Heading from "../components/Heading";
import api from "../services/api";
import PersonalInfo from "./personal-info";

const Home = lazy( () => import( "./home" ) );
const Produto = lazy( () => import( "./produto" ) );
const Cart = lazy( () => import( "./cart" ) );
const Adresses = lazy( () => import( "./adresses" ) );
const Checkout = lazy( () => import( "./checkout" ) );

const Search = lazy( () => import( "./search" ) );

const Payment = lazy( () => import( "./payment" ) );

const Navbar = lazy( () => import( '../components/Navbar' ) );
const Footer = lazy( () => import( '../components/Footer' ) );
const Whatsapp = lazy( () => import( '../components/Whatsapp' ) );

const AppRoutes = () => {
  const [ info, setInfo ] = useState( [] );

  useEffect( () => {
    getInfo();
  }, [] );

  async function getInfo() {
    await api.get( '/info' )
      .then( async ( res ) => {
        setInfo( res.data );
      } )
      .catch( ( err ) => {
        console.log( err );
      } )
  }

  return (
    <Suspense fallback={
      <>
        <p>Carregando...</p>
      </>
    }>
      <Heading info={ info } />
      <Routes>
        <Route path="/" element={ <><Navbar /> <Home /></> } />
        <Route path="/produto/:id" element={ <><Navbar /><Produto /></> } />
        <Route path="/:product/*" element={ <><Navbar /><Search /></> } />
        <Route path="/cart" element={ <><Navbar /><Cart /></> } />

        <Route path="/adresses" element={ <Adresses /> } />
        <Route path="/checkout" element={ <Checkout /> } />
        <Route path="/personal-info" element={ <PersonalInfo /> } />

        <Route path="/payment" element={ <Payment /> } />
      </Routes>
      <Whatsapp />
      <Footer info={ info } />
    </Suspense>
  )
};

export default AppRoutes;

/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components";
import React, { useEffect, useState } from 'react';
import PersonalInfoForm from "../PersonalInfoForm";
import ChakraFlex from "../ChakraFlex";
import PaymentInfoForm from "../PaymentInfoForm";
import ProductSummary from "../ProductSummary";
import FormWithDisabledSection from "../FormWithDisabledSection";

const Root = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 55px 0;
    `;

const PersonalInfo = () => {
    const isMobile = window.innerWidth <= 768;
    const [userInfo, setUserInfo] = useState({
        person: {
            email: "",
            name: "",
            cpf: "",
            cellphone: "",
            birth_date: ""
        },
        card: {
            adress: '',
            adress_number: '',
            city: '',
            state: '',
            zip_code: '',
            name_card: '',
            number: '',
            date: '',
            cvv: '',
            split: '',
            type: 'cartao'
        }
    });
    const [activeStep, setActiveStep] = useState(1);

    // Função para atualizar as informações do usuário
    const updateUserInfo = (fieldName, fieldValue) => {
        setUserInfo({
            ...userInfo,
            [fieldName]: fieldValue,
        });
    };

    useEffect(() => {
        if (window) {
            updateUserInfo('cart', window.sessionStorage.getItem('cart'))
        }
    }, [])

    return (
        <Root>
            <ChakraFlex direction={isMobile ? 'column' : 'row'}>
                <FormWithDisabledSection isEnabled={activeStep === 1}>
                    <PersonalInfoForm setActiveStep={setActiveStep} userInfo={userInfo} updateUserInfo={updateUserInfo} />
                </FormWithDisabledSection>
                <FormWithDisabledSection isEnabled={activeStep === 2}>
                    <PaymentInfoForm setActiveStep={setActiveStep} userInfo={userInfo} updateUserInfo={updateUserInfo} />
                </FormWithDisabledSection>
                <ProductSummary userInfo={userInfo} />
            </ChakraFlex>
        </Root >
    );
}

export default PersonalInfo;
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Input, InputTitle, InputWrapper } from "./FormComponents";
import axios from "axios";

const BillingAdressForm = (props) => {
    const { card, setCard } = props;
    const [cep, setCep] = useState('');

    // Função para consultar o CEP no ViaCEP
    const fetchAdressData = async () => {
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            const data = response.data;

            if (!data.erro) {
                // Preenche os campos com os dados do CEP
                setCard({
                    ...card,
                    zip_code: cep,
                    adress: data.logradouro,
                    city: data.localidade,
                    state: data.uf,
                });
            } else {
                // Limpa os campos se o CEP não for encontrado
                setCard({
                    ...card,
                    adress: '',
                    city: '',
                    state: '',
                });
            }
        } catch (error) {
            console.error('Erro ao consultar o CEP:', error);
        }
    };

    // Efeito para consultar o CEP quando o campo de CEP (cep) muda
    useEffect(() => {
        if (cep.length >= 8) {
            fetchAdressData();
        }
    }, [cep]);

    return (
        <>
            ENDEREÇO DE COBRANÇA
            <InputWrapper>
                <InputTitle>CEP</InputTitle>
                <Input
                    type="text"
                    placeholder="Seu CEP"
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                    maxLength={8}
                />
            </InputWrapper>

            <InputWrapper>
                <InputTitle>Nome Completo</InputTitle>
                <Input
                    type="text"
                    placeholder="Seu nome completo"
                    value={card.name}
                    onChange={(e) => setCard({ ...card, name: e.target.value })}
                />
            </InputWrapper>

            <InputWrapper>
                <InputTitle>Endereço</InputTitle>
                <Input
                    type="text"
                    placeholder="Seu endereço"
                    value={card.adress}
                    onChange={(e) => setCard({ ...card, adress: e.target.value })}
                />
            </InputWrapper>

            <InputWrapper>
                <InputTitle>Número</InputTitle>
                <Input
                    type="text"
                    placeholder="Número"
                    value={card.adress_number}
                    onChange={(e) => setCard({ ...card, adress_number: e.target.value })}
                />
            </InputWrapper>

            <InputWrapper>
                <InputTitle>Cidade</InputTitle>
                <Input
                    type="text"
                    placeholder="Sua cidade"
                    value={card.city}
                    onChange={(e) => setCard({ ...card, city: e.target.value })}
                />
            </InputWrapper>

            <InputWrapper>
                <InputTitle>Estado</InputTitle>
                <Input
                    type="text"
                    placeholder="Seu estado"
                    value={card.state}
                    onChange={(e) => setCard({ ...card, state: e.target.value })}
                />
            </InputWrapper>
        </>
    );
};

export default BillingAdressForm;
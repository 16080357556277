import React from 'react';
import styled from 'styled-components';
import ChakraFlex from './ChakraFlex';

const ProductItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #ddd; /* Borda cinza claro em cima */
  border-bottom: 1px solid #ddd; /* Borda cinza claro embaixo */
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductName = styled.div`
  font-weight: 600; /* Negrito menos forte */
  font-size: 16px; /* Tamanho da fonte menor */
`;

const ProductPrice = styled.div`
  color: #00a65a; /* Cor do preço */
`;

const ProductQuantity = styled.div`
  font-size: 12px; /* Tamanho da fonte menor */
`;

const ProductTotalPrice = styled.div`
  font-weight: 800; /* Negrito forte */
  color: #00a65a; /* Cor do preço total */
`;

const ProductSummaryBox = (props) => {
  const { product } = props;
  return (
    <ChakraFlex direction="column" margin="0">
      <ProductItem>
        <ProductInfo>
          <ProductName>{product.name.length >= 15 ? product.name.substring(0, 15) + '...' : product.name}</ProductName>
          <ProductPrice>{Number(product.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</ProductPrice>
        </ProductInfo>
        <ChakraFlex direction="column">
          <ProductTotalPrice>{(Number(product.price) * product.checkout).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</ProductTotalPrice>
          <ProductQuantity>Quantidade: {product.checkout}</ProductQuantity>
        </ChakraFlex>
      </ProductItem>
    </ChakraFlex>
  );
};

export default ProductSummaryBox;

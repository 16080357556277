import { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { IconContext } from 'react-icons';
import { FaAngleRight, FaPhoneAlt, FaRegClock } from "react-icons/fa";
import { HiMenu, HiX } from "react-icons/hi";

import api from "../services/api";
import { Link } from "react-router-dom";

const Root = styled.div`
    position: fixed;
    z-index: 1;
    // padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
`;

const Item = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1.2em;
    border-bottom: 1px solid;
    font-size: 1rem;
    font-weight: 400;
    // background-color: #F1F1F1;
    background-color: #5241d3;

    ${(props) => props.pointer && css`cursor: pointer;`}
    ${(props) => props.pointer && css`background-color: #F1F1F1;`}
    ${(props) => props.pointer && css`color: #343434;`}
    ${(props) => props.pointer && css`&:hover {
        background-color: #d9d9d9;
    }`}
`;

const SubMenu = styled.p`
    background-color: #F1F1F1;
    border-bottom: 1px solid #343434;
    color: #343434;
    padding: 0.5em 1.8em;
    cursor: pointer;

    &:hover {
        background-color: #d9d9d9;
    }
`;

const Contato = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const WorkingTime = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const Dropdown = (data) => {
    const [dropdown, setDropdown] = useState(false);
    const [categoryTree, setCategoryTree] = useState([]);
    const [show, setShow] = useState(0);

    useEffect(() => { }, [show]);

    async function search(category_id) {
        await api.get('/categories/tree/' + category_id)
            .then(async (res) => {
                const result = res.data;
                setCategoryTree(result.Category[0].Category.children);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    async function close() {
        setDropdown(false);
        setShow(0);
    }

    async function exibir(id) {
        if (id && id !== show) {
            search(id);
            setShow(id);
        } else {
            setShow(0);
        }
    }

    const Container = styled.div`
        display: ${dropdown === true ? 'flex' : 'none'};
        flex-direction: column;
        overflow: hidden;
    `;

    return (
        <>
            <IconContext.Provider value={{ size: '35px' }} >
                <HiMenu style={{ cursor: 'pointer' }} onClick={() => setDropdown(true)} />
            </IconContext.Provider>
            <Container className={dropdown && 'lockContainer'}>
                {dropdown &&
                    <Root>
                        <Item>
                            <Contato>
                                <IconContext.Provider value={{ size: '25px', color: "#fbc900" }} >
                                    <FaPhoneAlt />
                                </IconContext.Provider>
                                <div>
                                    {data.info.phone_number_1 && <p>{data.info.phone_number_1}</p>}
                                    {data.info.phone_number_2 && <p>{data.info.phone_number_2}</p>}
                                    {data.info.phone_number_3 && <p>{data.info.phone_number_3}</p>}
                                    {data.info.email_1 && <p>{data.info.email_1}</p>}
                                    {data.info.email_2 && <p>{data.info.email_2}</p>}
                                </div>
                            </Contato>
                            <IconContext.Provider value={{ size: '35px', color: '#fbc900' }} >
                                <HiX style={{ cursor: 'pointer' }} onClick={() => close()} />
                            </IconContext.Provider>
                        </Item>
                        <Item>
                            <WorkingTime>
                                <IconContext.Provider value={{ size: '35px', color: "#fbc900" }} >
                                    <FaRegClock />
                                </IconContext.Provider>
                                <p style={{ width: 'fit-content' }}>
                                    {data.info.office_hour}
                                </p>
                            </WorkingTime>
                        </Item>
                        <Item pointer onClick={() => exibir(51)}>
                            METAIS PARA CALÇADOS, BOLSAS E ACESSÓRIOS <FaAngleRight />
                        </Item>
                        {
                            categoryTree.length > 0 && show === 51 && categoryTree.map((item, key) => (
                                <Link key={key} to={"/" + item.Category.slug} state={{ category: item.Category.id }}>
                                    <SubMenu key={key}>{item.Category.name}</SubMenu>
                                </ Link>
                            ))
                        }
                        <Item pointer onClick={() => exibir(79)}>
                            TROFÉUS E MEDALHAS <FaAngleRight />
                        </Item>
                        {
                            categoryTree.length > 0 && show === 79 && categoryTree.map((item, key) => (
                                <Link key={key} to={"/" + item.Category.slug} state={{ category: item.Category.id }}>
                                    <SubMenu key={key}>{item.Category.name}</SubMenu>
                                </ Link>
                            ))
                        }
                        <Item pointer onClick={() => exibir(121)}>
                            ARTIGOS DECORATIVOS E PROJETOS PERSONALIZADOS <FaAngleRight />
                        </Item>
                        {
                            categoryTree.length > 0 && show === 121 && categoryTree.map((item, key) => (
                                <Link key={key} to={"/" + item.Category.slug} state={{ category: item.Category.id }}>
                                    <SubMenu key={key}>{item.Category.name}</SubMenu>
                                </ Link>
                            ))
                        }
                    </Root>
                }
            </Container >
        </>
    )
}

export default Dropdown;
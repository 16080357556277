/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, InputTitle, InputWrapper } from './FormComponents';

const PaymentForm = styled.div`
  /* Estilos para o formulário de pagamento */
`;

const StyledSelect = styled.select`
  background-color: white; /* Fundo branco */
  border: 1px solid #ccc; /* Borda de cor cinza claro */
  border-radius: 4px; /* Borda levemente arredondada */
  padding: 8px; /* Espaçamento interno */
`;

const SelectComponent = ({ options, value, onChange }) => {
  return (
    <StyledSelect value={value} onChange={onChange}>
      <option value="" disabled>
        -Selecione-
      </option>
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </StyledSelect>
  );
};

const CreditCardFields = (props) => {
  const { setCard, card } = props;
  const [cart, setCart] = useState([]);
  const [installmentOptions, setInstallmentOptions] = useState([]);
  const [selectedInstallmentLabel, setSelectedInstallmentLabel] = useState('');

  useEffect(() => {
    if (window) {
      setCart(JSON.parse(window.sessionStorage.getItem('cart')));
    }
  }, []);

  // Função para calcular os valores das parcelas com base na soma dos preços dos produtos nos carrinhos
  const calculateInstallmentOptions = () => {
    const checkout = JSON.parse(sessionStorage.getItem('checkout'));
    const totalCartPrice = checkout.reduce(
      (total, product) => total + Number(product.total),
      0
    );

    // Taxa de juros de 1,99% ao mês
    const monthlyInterestRate = 0.0199; // 1,99% ao mês

    // Crie opções de parcelamento de 1x a 12x
    const options = Array.from({ length: 12 }, (_, index) => {
      let installmentValue = 0;
      if (index < 10) {
        // Parcelas sem juros para os primeiros 9 meses
        installmentValue = totalCartPrice / (index + 1);
      } else {
        // A partir do 10º mês, aplique juros
        installmentValue =
          (totalCartPrice / (index + 1)) *
          Math.pow(1 + monthlyInterestRate, index - 9); // Subtrai 8 para calcular os juros a partir do 10º mês
      }
      if (installmentValue > 5) {
        return `${index + 1}x - R$ ${installmentValue.toFixed(2)}`;
      }
      return ""
    });

    setInstallmentOptions(options.filter(item => item !== ""));
  };

  useEffect(() => {
    calculateInstallmentOptions();
  }, [cart]);

  // Função para atualizar o número de parcelas selecionado
  const handleInstallmentChange = (e) => {
    const selectedInstallmentIndex = parseInt(e.target.value) - 1;
    setCard({ ...card, split: `${parseInt(e.target.value)}` })
    setSelectedInstallmentLabel(installmentOptions[selectedInstallmentIndex]);
  };

  const handleNumericInputChange = (e, setValue, maxLength) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, '');
    if (maxLength && inputValue.length > maxLength) {
      inputValue = inputValue.slice(0, maxLength);
    }
    setValue(inputValue);
  };

  const formatExpirationDate = (inputValue) => {
    // Remove todos os caracteres não numéricos
    const numericValue = inputValue.replace(/[^0-9]/g, '');

    // Formata para MM/AA
    if (numericValue.length <= 2) {
      return numericValue;
    } else if (numericValue.length <= 6) {
      return `${numericValue.slice(0, 2)}/${numericValue.slice(2)}`;
    } else {
      // Se o usuário digitar mais de 4 caracteres, trunca para 6
      return `${numericValue.slice(0, 2)}/${numericValue.slice(4)}`.slice(0, 5);
    }
  };

  // Função para aceitar apenas números no CVV e limitar a 3 caracteres
  const formatCVV = (inputValue) => {
    // Remove todos os caracteres não numéricos
    const numericValue = inputValue.replace(/[^0-9]/g, '');

    // Limita a 3 caracteres
    return numericValue.slice(0, 3);
  };

  return (
    <>
      {/* Campo select para escolher o número de parcelas */}
      <InputWrapper>
        <InputTitle>Número de Parcelas</InputTitle>
        <SelectComponent
          options={installmentOptions}
          onChange={handleInstallmentChange}
          value={selectedInstallmentLabel}
        />
      </InputWrapper>

      <InputWrapper>
        <InputTitle>Nome no Cartão</InputTitle>
        <Input type="text" placeholder="Nome no cartão" value={card.name_card} onChange={(e) => setCard({ ...card, name_card: e.target.value })} />
      </InputWrapper>

      <InputWrapper>
        <InputTitle>Número do Cartão</InputTitle>
        <Input
          type="text"
          placeholder="Numero do Cartao"
          value={card.number}
          maxLength={16}
          onChange={(e) =>
            handleNumericInputChange(e, (newValue) =>
              setCard({ ...card, number: newValue })
            )
          }
        />
      </InputWrapper>

      <InputWrapper>
        <InputTitle>Validade</InputTitle>
        <Input
          type="text"
          placeholder="MM/AAAA"
          value={card.date}
          onChange={(e) => setCard({ ...card, date: formatExpirationDate(e.target.value) })}
        />
      </InputWrapper>

      <InputWrapper>
        <InputTitle>CVV</InputTitle>
        <Input
          type="text"
          placeholder="CVV"
          value={card.cvv}
          onChange={(e) => setCard({ ...card, cvv: formatCVV(e.target.value) })}
        />
      </InputWrapper>
    </>
  );
};


const PixFields = (props) => {
  /* Componente para campos de Pix */
};

const BoletoFields = (props) => {
  /* Componente para campos de boleto */
};

const CheckoutForm = (props) => {
  const { setCard, card } = props;
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('cartao');

  const handlePaymentMethodChange = (e) => {
    setSelectedPaymentMethod(e.target.value);
    setCard({ ...card, type: e.target.value })
  };

  return (
    <PaymentForm>
      <StyledSelect
        value={selectedPaymentMethod}
        onChange={handlePaymentMethodChange}
      >
        <option value="cartao">Cartão</option>
        <option value="pix">Pix</option>
        <option value="boleto">Boleto</option>
      </StyledSelect>

      {selectedPaymentMethod === 'cartao' && <CreditCardFields setCard={setCard} card={card} />}
      {selectedPaymentMethod === 'pix' && <PixFields setCard={setCard} card={card} />}
      {selectedPaymentMethod === 'boleto' && <BoletoFields setCard={setCard} card={card} />}

    </PaymentForm>
  );
};

export default CheckoutForm;

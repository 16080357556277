import styled from 'styled-components';

const ChakraFlex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'stretch'};
  align-content: ${({ alignContent }) => alignContent || 'stretch'};
  gap: ${({ gap }) => gap || '10px'};
  margin: ${({ margin }) => margin || '0'};
`;

export default ChakraFlex;
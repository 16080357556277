import { createContext, useState } from "react"

const CheckoutContext = createContext();

export function CheckoutProvider({ children }) {

    const [finalizado, setFinalizado] = useState(false);
    const [orderYapay, setOrderYapay] = useState('');
    const [textSearch, setTextSearch] = useState("");
    const state = {
        finalizado, setFinalizado,
        orderYapay, setOrderYapay,
        textSearch, setTextSearch
    }

    return (
        <CheckoutContext.Provider value={state}>
            {children}
        </CheckoutContext.Provider>
    )
}


export default CheckoutContext
import { useContext } from "react";
import { Link } from "react-router-dom";
import { IconContext } from 'react-icons';
import { FaFacebookF, FaTiktok, FaInstagram, FaSearch } from "react-icons/fa";
import styled from "styled-components";

import CheckoutContext from "../context/CheckoutContext";

const SocialMedia = () => {

    const { setTextSearch } = useContext( CheckoutContext );

    const SocialIcons = styled.div`
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 0px;
        margin: 0px;
    `;

    const Buscar = styled.div`
        display: flex;
        align-items: center;
    `;

    const Input = styled.input`
        border: none;
        font-size: 14px;
        padding: 3px 10px;
        width: 100%;
        height: 30px;
        border-radius: 50px;
        
        @media(max-width: 800px) {
            width: 200px;
            margin: 0px 10px 0px 10px;
        }
        @media(max-width: 600px) {
            width: 300px;
            margin: 20px 0px 20px 0px;
        }
    `;

    const handleSearch = () => {
        const searchTerm = document.getElementById( "search-bar" ).value;
        setTextSearch( searchTerm );
    }

    return (
        <SocialIcons>
            <IconContext.Provider value={ { size: '16px' } }>
                <a style={ { marginLeft: 10, cursor: "pointer" } } href="https://www.facebook.com/RigdomDesign8">  <FaFacebookF /></a>
            </IconContext.Provider>

            <IconContext.Provider value={ { size: '19px' } }>
                <a style={ { marginLeft: 10, cursor: "pointer" } } href="https://www.instagram.com/rigdomdesign/"> <FaInstagram /></a>
            </IconContext.Provider>

            <IconContext.Provider value={ { size: '19px' } }>
                <a style={ { marginLeft: 10, cursor: "pointer" } } href="https://www.tiktok.com/@rigdomdesign"> <FaTiktok /></a>
            </IconContext.Provider>



            <Buscar>
                <Input type="text" id="search-bar" name="search-bar" placeholder="Buscar" />
            </Buscar>
            <IconContext.Provider value={ { size: '16px' } }>
                <Link to={ "/search" } onClick={ () => handleSearch() } >
                    <FaSearch style={ { cursor: 'pointer' } } />
                </ Link>
            </IconContext.Provider>

        </SocialIcons>
    )
}

export default SocialMedia;
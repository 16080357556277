import React from "react";
import { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";

import { breakAt, BreakpointsSizes } from "./BreakpointsSizes";

const GlobalStyle = createGlobalStyle`    
    html {
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        font-size: 100%;
        color: #212121;
        box-sizing: border-box;
    }

    *, *:before, *:after {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    body {
      margin-top: 0;
      // margin-left: 10px;
      // margin-right: 10px;
    }

    body:has(.lockContainer) {
      overflow: hidden;
    }

    a {
      all: unset;
    }

    a, button {
      font-family: "Poppins", sans-serif;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance:textfield; /* Firefox */
    }

    h1, h2, h3, h4, h5, h6 {
      line-height: 1.3;
      font-weight: 700;
      letter-spacing: 2px;

      strong {
        color: ${(props) => props.theme.colors.primary.main};
      }
    }

    h1 {
      font-size: 2.5rem;

      ${breakAt(BreakpointsSizes.lg)} {
        font-size: 3.75rem;
      }
    }

    h2 {
      font-size: 2rem;

      ${breakAt(BreakpointsSizes.lg)} {
        font-size: 3.125rem;
      }
    }

    h3 {
      font-size: 1.9rem;

      ${breakAt(BreakpointsSizes.lg)} {
        font-size: 2.5rem;
      }
    }

    h4 {
      font-size: 1.3rem;
      font-weight: 600;

      ${breakAt(BreakpointsSizes.lg)} {
        font-size: 2.125rem;
      }
    }

    h5 {
      font-size: 1.2rem;
      font-weight: 300;

      ${breakAt(BreakpointsSizes.lg)} {
        font-size: 1.5rem;
      }
    }

    h6 {
      font-size: 1.1rem;
      font-weight: 600;
      ${breakAt(BreakpointsSizes.lg)} {
        font-size: 1.25rem;
      }
    }

    select {
      background-color: transparent;
      border: none;
      padding: 0 1em 0 0;
      margin: 0;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
    
      // Stack above custom arrow
      z-index: 1;
    
      // Remove focus outline, will add on alternate element
      outline: none;
    }

    .swiper-button-next, .swiper-button-prev {
      width: 18px !important;
      height: 18px !important;
      padding: 16px;
      border: 2px solid #0f8a1d;
      border-radius: 6px;
      outline: none;
      color: #FFF !important;
      background-color: #0f8a1d;
    }

    .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
      color: #0f8a1d !important;
      background-color: transparent;
    }

    .swiper-button-next:after, .swiper-button-prev:after {
      font-size: 14px !important;
      font-weight: 900;
    }

    #trayDescription table {
      border-collapse: collapse;
      border: 1px solid black;

      @media (max-width: 736px){
        padding: 0;
        width: 100%!important;
        max-width: 100%!important;
      }
    }

    #trayDescription th, td {
      border: 1px solid black;
      padding: 8px;

      @media (max-width: 736px){
        padding: 1px;
      }
    }

    #trayDescription tbody tr:nth-child(even) {
      background-color: #eee;
    }

    #trayDescription tbody tr:nth-child(odd) {
      background-color: #fff;
    }
`;

const GlobalStyledComposed = () => (
  <>
    <GlobalStyle />
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;600;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  </>
);

export default GlobalStyledComposed;

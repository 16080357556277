import ChakraFlex from "./ChakraFlex";
import ProductBox from "./ProductSummaryBox";
import { NumberBadge, Title } from "./FormComponents";
import React, { useEffect, useState } from 'react';

const ProductSummary = (props) => {
    const cart = JSON.parse(props.userInfo.cart ?? "[]");
    const [checkout, setCheckout] = useState(undefined);
    useEffect(() => {
        if (window) {
            setCheckout(JSON.parse(window.sessionStorage.getItem('checkout')))
        }
    }, []);
    return (
        <ChakraFlex
            style={{
                backgroundColor: "#f2f2f2", /* Fundo levemente cinza claro */
                border: "1px solid #ccc", /* Borda cinza claro */
                padding: "15px", /* Espaçamento interno */
                borderRadius: "8px", /* Borda arredondada */
            }}
            direction="column"
            gap="0"
        >
            <ChakraFlex style={{ marginBottom: "10px" }}>
                <NumberBadge>✔</NumberBadge>
                <Title>Resumo do Pedido</Title>
            </ChakraFlex>
            <>
                {checkout !== undefined ? cart.map((item, index) => <ProductBox product={item} key={index} />) : null}
                <ChakraFlex direction="column" margin="0">
                    <ChakraFlex direction="column" style={{ fontWeight: "bold" }}>
                        Total: {(cart !== undefined && checkout !== undefined)
                            ? (Number(checkout.map(item => Number(item.shippingValue)).reduce((partialSum, a) => partialSum + a, 0))
                                + cart.map(item => (Number(item.price) * item.checkout)).reduce((partialSum, a) => partialSum + a, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : null}
                        {" "}
                        ({cart.map(item => (Number(item.price) * item.checkout)).reduce((partialSum, a) => partialSum + a, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} + Frete)
                    </ChakraFlex>
                </ChakraFlex>
            </>
        </ChakraFlex>
    )
}

export default ProductSummary;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import ChakraFlex from "./ChakraFlex";
import { NumberBadge, SaveButton, Title } from "./FormComponents";
import CheckoutForm from './CheckoutForm';
import BillingAddressForm from './BillingAddressForm';
import api from '../services/api';
import CheckoutContext from "../context/CheckoutContext";
import styled, { css } from "styled-components";
import { useNavigate } from 'react-router-dom'
import EventEmitter from '../utils/events';

const Snackbar = styled.div` 
  visibility: hidden;
  align-self: center;
  min-width: 250px;
  max-width: 450px;
  font-weight: 800;
  background-color: #F1F1F1;
  color: red;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  bottom: 30px;

  font-size: 1.3rem;

  ${(snack) =>
        snack &&
        css`
      visibility: visible;
      -webkit-animation: fadein 0.5s, fadeout 0.5s 3.5s;
      animation: fadein 0.5s, fadeout 0.5s 3.5s;
  `}

  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
  
  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
`;

const PaymentInfoForm = (props) => {
    const FinalizandoContext = useContext(CheckoutContext);
    const { userInfo, updateUserInfo } = props;
    const navigate = useNavigate()
    const [errorInPayment, setErrorInPayment] = useState(false);
    const [errormsg, setErrormsg] = useState("");
    const [processingPayment, setProcessingPayment] = useState(false);
    const [card, setCard] = useState(
        {
            adress: '',
            adress_number: '',
            city: '',
            state: '',
            zip_code: '',
            name_card: '',
            number: '',
            date: '',
            cvv: '',
            split: '',
            type: 'cartao'
        }
    )
    const [dados, setDados] = useState(undefined);

    useEffect(() => {
        updateUserInfo('card', { ...userInfo.card, ...card })
    }, [card]);

    useEffect(() => {
        setCard({ ...userInfo.card, ...card })
    }, []);

    useEffect(() => {
        if (window) {
            const checkout = window.sessionStorage.getItem('checkout')
            setDados(JSON.parse(checkout))
        }
    }, [window]);

    async function getOrder() {
        const response = await api.get(`/vindi/yapay/find/order?cpf=${userInfo.person.cpf}`)
        const data = await response.data;
        await FinalizandoContext.setOrderYapay(data.order[0].data_response.transaction);
        navigate('/payment');
        window.sessionStorage.removeItem('cart')
        EventEmitter.dispatch('addCart')
    }


    const handleConfirmPayment = async () => {
        try {
            setProcessingPayment(true)
            for (const cardInfo in card) {
                if (card[cardInfo] === ''
                    && ['adress', 'adress_number', 'city', 'state', 'zip_code'].includes(cardInfo)) {
                    throw Error("Dados de endereço faltantes")
                }
                if (card.type === 'cartao'
                    && card[cardInfo] === ''
                    && ['name_card', 'number', 'date', 'cvv', 'split'].includes(cardInfo)) {
                    throw Error("Dados do cartão faltantes")
                }
            }

            if (card.type === 'cartao' && card["date"].length < 7) {
                throw Error("Insira a data de validade do cartão completa (MM/AAAA)")
            }

            const mappedDados = await dados.map((adress) => {
                adress.itens = adress.itens.filter(entry => entry.quantity > 0)
                return adress
            })

            const body = { type: card.type, dados: mappedDados, card: card, person: userInfo.person }
            await api.post('/checkout/finished', body).catch(e => {
                throw Error(e.response.data.message)
            })
            await getOrder()
        } catch (error) {
            console.error("ERRO:" + error)
            setErrorInPayment(true);
            setErrormsg(error.message ?? "Confira seus dados")
            setTimeout(() => setErrorInPayment(false), 3000)
        } finally {
            setProcessingPayment(false)
        }
    }

    return (
        <>
            <ChakraFlex direction="column"
                style={{
                    backgroundColor: "#f2f2f2", /* Fundo levemente cinza claro */
                    border: "1px solid #ccc", /* Borda cinza claro */
                    padding: "15px", /* Espaçamento interno */
                    borderRadius: "8px", /* Borda arredondada */
                }}
            >

                <ChakraFlex>
                    <NumberBadge>2</NumberBadge>
                    <Title>Informações de Pagamento</Title>
                </ChakraFlex>
                FORMA DE PAGAMENTO
                <CheckoutForm card={card} setCard={setCard} />
                <BillingAddressForm card={card} setCard={setCard} />
                <SaveButton isLoading={processingPayment} onClick={handleConfirmPayment}>Finalizar Compra</SaveButton>
                {errorInPayment && <Snackbar>
                    Erro no pagamento: {errormsg}
                </Snackbar>}
            </ChakraFlex>
        </>
    );
};

export default PaymentInfoForm;
